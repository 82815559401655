<template>
<div class="container-fluid">
	<PageTitle :no-add="true" />

	<div class="card">
		<div class="card-header">
			<h5 class="card-title">Filter</h5>
		</div>
		<div class="card-body">
			<b-row>
				<template v-if="mrOffice.length">
					<b-col :md="colMd">
						<b-form-group label="Kantor">
							<VSelect 
								v-model="filter.office" 
								placeholder="-- Semua --"
								:options="mrOffice" 
								@input="getUnitKerja"
								:reduce="v=>String(v.value)" 
							/>
						</b-form-group>
					</b-col>
				</template>
				<template v-if="isNotRoDivisi&&mrUnitKerja.length">
					<b-col :md="colMd">
						<b-form-group :label="isPusat ? 'Group': 'Unit Kerja'">
							<b-overlay
								:show="loadingUnitKerja"
								>
							<VSelect 
								v-model="filter.unitKerja" 
								placeholder="-- Semua --"
								:options="mrUnitKerja" 
								:reduce="v=>String(v.value)" 
							/>
							</b-overlay>
						</b-form-group>
					</b-col>
				</template>
				<b-col :md="colMd">
					<b-form-group label="Tahun">
						<VSelect 
							v-model="filter.tahun" 
							placeholder="-- Semua --"
							:options="periodeTahun" 
							:reduce="v=>String(v.value)" 
						/>
					</b-form-group>
				</b-col>
				<b-col :md="colMd">
					<b-form-group label="Bulan">
						<VSelect 
							v-model="filter.bulan" 
							placeholder="-- Semua --"
							:options="periodeBulan" 
							:reduce="v=>String(v.value)" 
						/>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row align-h="end" class="mt-2">
				<b-col md=2>
					<button type="button" class="btn btn-secondary btn-block" @click="doReset"> Reset </button>
				</b-col>
				<b-col md=2>
					<button type="button" class="btn btn-info btn-block" @click="doFilter"> Cari </button>
				</b-col>
			</b-row>
		</div>
	</div>

	<template v-if="isList">
		<card-list 
			:filter.sync="filter"
			:dataListing.sync="data"
			:idKey="idKey"
			:fields="fields"

			:no-btn-mass="true"
			:no-multi-select="true"
		> 
			<template #trp_bulan="data">
				{{'2021-'+data.scope.value+'-01' | moment('MMM')}} {{data.scope.item.trp_tahun}}
			</template>
			<template #mb_name="data">
				{{data.scope.item.mb_name || '-'}}
			</template>
			<template #doc="data">
				<template v-if="data.scope.item.mitigasi.length">
					<a 
						href="javascript:;" 
						class="txt_link_download"
						@click="doOpenModal(data.scope.item)"
					> 
						{{data.scope.item.mitigasi.length}} Mitigasi
					</a>
					
				</template>
				<span v-else>Tidak Ada Mitigasi</span>
			</template>
			<template #trr_catatan_pengendalian="data">
				{{data.scope.value || '-'}}
			</template>
			<template #trr_doc_evidence="data">
				<template v-if="data.scope.value">
					<ImagePreview 
							:src="uploader(data.scope.value)"
							type="blank"
						>
						<a :href="uploader(data.scope.value)" target="_blank" rel="noopener noreferrer" class="txt_link_download"> 
							<i class="far fa-file-pdf"></i> Dokumen
						</a>
					</ImagePreview>
				</template>
				<span v-else>Tidak Ada Dokumen</span>
			</template>
			<template #download="data">
				<b-button
						variant="secondary"
						class="ml-2"
						@click="doDownload(data.scope.item[idKey])"
					>
						<div 
							v-b-tooltip.hover
							title="Download .zip"
						>
							<i class="ti-download"></i>
						</div>
					</b-button>
			</template>
		</card-list>
	</template>

	<b-modal 
		id="ModalDetail" 
		:title="'Daftar Dokumen Mitigasi - '+(modal.mr_name||'')"
		size="xl"
		ok-only
		:hide-footer="true"
	>
		<div class="inbox-center table-responsive">
			<table class="table m-b-0">
				<thead>
					<tr class=" text-center  full-color-table full-purple-table">
						<th> # </th>
						<th> Nama Mitigasi </th>
						<th> Perkiraan Biaya (Rp) </th>
						<th> Perkiraan Jadwal </th>
						<th> PIC </th>
						<th> Doc. Mitigasi </th>
						<th> Hasil </th>
					</tr>
				</thead>
				<tbody>
					<tr 
						v-for="(v,k) in modal.mitigasi"
						:key="k"
						class="unread"
					>
						<td>{{k+1}}</td>
						<td>{{ v.nama }}</td>
						<td>Rp {{ v.biaya.format() }}</td>
						<td>{{ v.jadwal_awal | moment("DD MMMM YYYY") }} - {{ v.jadwal_akhir | moment("DD MMMM YYYY") }}</td>
						<td>{{v.pic}}</td>
						<td style="text-align:center">
							<template v-if="v.doc">
								<ImagePreview 
									:src="uploader(v.doc)"
									type="blank"
								>
									<a :href="uploader(v.doc)" target="_blank" rel="noopener noreferrer" class="txt_link_download"> 
										<i class="far fa-file-pdf"></i> Tersedia
									</a>
								</ImagePreview>
							</template>
							<span v-else>-</span>
						</td>
						<td>
							{{v.hasil||'-'}}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</b-modal>
</div>
</template>
<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
import Gen from '@libs/Gen.js'

let _ = global._
export default {
	extends:GlobalVue,
	components: {
		PageTitle, CardList
	},
	data(){
		return {
			modal:{},
			idKey:'trr_id',
			fields:[
				{ key:'number', label:'#' },
				{ key:'trp_bulan', label:'Periode', is_custom: true },
				{ key:'mo_name', label:'Nama Kantor' },
				{ key:'muk_description', label:'Unit Kerja' },
				{ key:'mr_name', label:'Nama Risiko' },
				{ key:'mb_name', label:'Bidang', is_custom: true },
				{ key:'trr_doc_evidence', label:'Dokumen Pengendalian', is_custom: true },
				{ key:'doc', label:'Mitigasi', is_custom:true },
				{ key:'trr_catatan_pengendalian', label:'Catatan', thClass: "w_200", is_custom: true},
				{ key:'download', is_custom:true },
			],
			mrOffice: [],
			officeLevel: '',
			mrUnitKerja: [],
			loadingUnitKerja:false,
			periodeBulan: [
				{
					label: 'Januari',
					value: 1
				},
				{
					label: 'Februari',
					value: 2
				},
				{
					label: 'April',
					value: 4
				},
				{
					label: 'Mei',
					value: 5
				},
				{
					label: 'Juli',
					value: 7
				},
				{
					label: 'Agustus',
					value: 8
				},
				{
					label: 'Oktober',
					value: 10
				},
				{
					label: 'November',
					value: 11
				},
			],
			periodeTahun: []
		}
	},
  methods: {
		doOpenModal(data){
      this.$set(this,'modal', _.clone(data))
      this.$bvModal.show("ModalDetail")
    },
    doDownload(download_id){
      let reqUrl = process.env.VUE_APP_API_URL + "/api/bo/get/"+this.modulePage
      reqUrl += (this.pageSlug?"/"+this.pageSlug:"")
      reqUrl += (this.pageId?"/"+this.pageId:"")
      Object.keys(this.$route.query).forEach((k) => this.$route.query[k] == null && delete this.$route.query[k]);

      let params = {
				download:download_id, 
				token: Gen.getCookie("bo_auth")||""
			}
      params = new URLSearchParams(params).toString()
      reqUrl += '?'+params
      window.open(reqUrl, '_blank')
    },
		getUnitKerja(){
      const officeId = this.filter.office;
      //const officeIndex = this.mrOffice.findIndex(v => v.value == officeId)
      //const officeLevel = this.mrOffice[officeIndex].level
			const payload = { 
				params: {
					type: "getUnitKerja",
					office: officeId,
				}
			}

			this.loadingUnitKerja=true
			this.$set(this.filter, 'unitKerja', null);
			Gen.apiRest("/get/" + this.modulePage + '/detail', payload).then(
				res => {
					this.loadingUnitKerja=false
					this.mrUnitKerja = res.data.data
				}
			).catch(err => {
				this.loadingUnitKerja=false
				console.log(err)
			})
    },
  },
	mounted(){
		this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    }
  },
	computed: {
		colMd(){
			return this.isNotRoDivisi ? 12 / [this.mrOffice, this.mrUnitKerja, this.periodeBulan, this.periodeTahun].filter(v => v.length).length : 6 
		},
		isPusat(){
      return _.filter(this.mrOffice, (v) => {
        return v.value == this.filter.office && v.level=='pusat';
      }).length;
    },
		isNotRoDivisi(){
      return (this.user.officeLevel!=='pusat')||(this.user.officeLevel==='pusat'&&this.user.unitKerjaId.length === 0)
    }
	}
}
</script>